/* Include */
@font-face {
    font-family: "monument_extendedregular";
    src: url("https://www.yudiz.com/codepen/photography-banner/monumentextended-regular.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  .iPwghr a:not(:last-child):hover::after {
    width: 100%;
    background: var(--primary-color) !important;
}
a:hover {
  text-decoration: none;
}
  @font-face {
    font-family: "Extenda Trial 20 Micro";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-20Micro.woff2")
      format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Extenda Trial 30 Deca";
    src: url("https://www.yudiz.com/codepen/photography-banner/Extenda-30Deca.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  :root{
    --primary-color: #01889e;
  }
.image__icon{
  width: 25px;
  height: 25px;
  /* make image black */
  /* grayscale */
  filter: grayscale(100%);
 

}
  
.btnHome {
  padding: 15px 20px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  z-index: 1;
}

.btnHome::after,
.btnHome::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.btnHome::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}
body {
  margin: 0;
  background-color: #212121;
  background-image: url("https://www.yudiz.com/codepen/photography-banner/frame.png");
}
.btnHome::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.btnHome:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.btnHome:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.btnHome:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

body img.logo-backs2 {
  display: none !important;
}

#root img.logo-backs2 {
  display: none !important;
}
/* loader */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  --color: hsl(0, 0%, 87%);
  --animation: 2s ease-in-out infinite;
  height: 100vh;
}
.logo-backs2 {
  position: absolute;
  width: auto;
  opacity: 1;
  bottom: 3rem;
  left: 1.8rem;
  max-width: 20%;
  display: none !important;
}
.loader .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border: solid 2px var(--color);
  border-radius: 50%;
  margin: 0 10px;
  background-color: transparent;
  animation: circle-keys var(--animation);
}

.loader .circle .dot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color);
  animation: dot-keys var(--animation);
}

.loader .circle .outline {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: outline-keys var(--animation);
}

.circle:nth-child(2) {
  animation-delay: 0.3s;
}

.circle:nth-child(3) {
  animation-delay: 0.6s;
}

.circle:nth-child(4) {
  animation-delay: 0.9s;
}

.circle:nth-child(5) {
  animation-delay: 1.2s;
}

.circle:nth-child(2) .dot {
  animation-delay: 0.3s;
}

.circle:nth-child(3) .dot {
  animation-delay: 0.6s;
}

.circle:nth-child(4) .dot {
  animation-delay: 0.9s;
}

.circle:nth-child(5) .dot {
  animation-delay: 1.2s;
}

.circle:nth-child(1) .outline {
  animation-delay: 0.9s;
}

.circle:nth-child(2) .outline {
  animation-delay: 1.2s;
}

.circle:nth-child(3) .outline {
  animation-delay: 1.5s;
}

.circle:nth-child(4) .outline {
  animation-delay: 1.8s;
}

.circle:nth-child(5) .outline {
  animation-delay: 2.1s;
}

@keyframes circle-keys {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes dot-keys {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes outline-keys {
  0% {
    transform: scale(0);
    outline: solid 20px var(--color);
    outline-offset: 0;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    outline: solid 0 transparent;
    outline-offset: 20px;
    opacity: 0;
  }
}